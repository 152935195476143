<template>
  <div style="margin: -1.5rem -2rem">
    <div
      :class="[
        { expanded: true },
        skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
      ]"
      class="main-menu menu-shadow shadow overflow-hidden"
      style="height: calc(100vh - 62px); z-index: 996"
    >
      <div class="my-1 mx-2">
        <page-title></page-title>
      </div>

      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="{ maxScrollbarLength: 60, wheelPropagation: true }"
        class="main-menu-content scroll-area overflow-hidden"
        tagname="ul"
        @ps-scroll-y="
          (evt) => {
            shallShadowBottom = evt.srcElement.scrollTop > 0;
          }
        "
      >
        <vertical-nav-menu-items
          :items="menuTree"
          class="navigation navigation-main"
        />
      </vue-perfect-scrollbar>
    </div>
    <div
      v-if="currentMenu"
      style="
        width: calc(100% - 260px);
        height: calc(100vh - 62px);
        margin-left: 260px;
      "
    >
      <b-embed
        v-if="currentMenu.iframe"
        id="jsf-iframe"
        :src="currentMenu.iframe"
        allow-cross-origin
        allowfullscreen
        aspect="4by3"
        loading="lazy"
        type="iframe"
      ></b-embed>
      <template v-else>
        <div
          v-if="currentMenu.component_key === 'panel-group'"
          style="padding: 6.5rem 2rem 0 2rem"
        >
          <panel-group
            v-if="currentMenu.group_uid"
            :group-uid="currentMenu.group_uid"
            :show-title="false"
          ></panel-group>
        </div>

        <component v-else :is="currentMenu.component_key"></component>
      </template>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/views/layout/PageTitle";
import VerticalNavMenuItems from "@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import useAppConfig from "@core/app-config/useAppConfig";
import useVerticalNavMenu from "@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { nextTick, provide, ref, watch } from "@vue/composition-api";
import Embed from "@/views/panel-group/components/embed/View";
import axios from "axios";
import PanelGroup from "@/views/panel-group";

export default {
  name: "OperationsLayout",
  components: {
    PageTitle,
    Embed,
    VerticalNavMenuItems,
    VuePerfectScrollbar,
    PanelGroup,
    FaultInformation: () => import("./components/FaultInformation"),
    Operation: () => import("./components/Operation"),
    OperationMode: () => import("./components/OperationMode"),
    OperationSituation: () => import("./components/OperationSituation"),
    FaultTreatment: () => import("./components/FaultTreatment"),
    IntelligentDiagnosis: () => import("./components/IntelligentDiagnosis"),
    EquipmentMaintenance: () => import("./components/EquipmentMaintenance"),
    Inspection: () => import("./components/Inspection"),
    IntegratedDispatching: () => import("./components/IntegratedDispatching"),
    MultiTerminalRepair: () => import("./components/MultiTerminalRepair"),
    ServiceWork: () => import("./components/ServiceWork"),
    AlarmInformation: () => import("./components/AlarmInformation"),
    AlarmLogging: () => import("./components/AlarmLogging"),
    AlarmProcessing: () => import("./components/AlarmProcessing"),
    AutomaticFaceRecognition: () =>
      import("./components/AutomaticFaceRecognition"),
    VideoInspection: () => import("./components/VideoInspection"),
    VideoLinkage: () => import("./components/VideoLinkage"),
    BigScreen: () => import("./components/BigScreen"),
    EmergencyPreplan: () => import("./components/EmergencyPreplan"),
    SystemNetworkTopology: () => import("./components/SystemNetworkTopology"),
    InspectionList: () => import("./components/InspectionList.vue"),
    RepairList: () => import("./components/repair-list/RepairList.vue"),
  },
  props: ["menuTree"],
  setup(props) {
    const { skin } = useAppConfig();
    const shallShadowBottom = ref(false);
    const currentMenu = ref(null);
    const { isMouseHovered } = useVerticalNavMenu(props);
    provide("isMouseHovered", isMouseHovered);
    watch(
      () => store.state.menus.inner_menu_current,
      (current) => {
        if (current.iframe) {
          //若链接不以http开始，则加上当前网址的http协议
          const location_protocol = window.location.protocol;
          if (current.iframe.slice(0, 4) !== "http") {
            current.iframe = location_protocol + "//" + current.iframe;
          }
          //对小程序链接单独处理
          if (current.iframe.indexOf("v4.qiyebox.com") > -1) {
            axios
              .get("https://v4.qiyebox.com/admin/user/info/e9/token")
              .then((res) => {
                const tenant_id = res.data.data.tenant_id;
                const access_token = res.data.data.access_token;
                let symbol = current.iframe.indexOf("?") > -1 ? "&" : "?";
                current.iframe +=
                  symbol +
                  "tenant_id=" +
                  tenant_id +
                  "&access_token=" +
                  access_token;
              });
          }
        }
        currentMenu.value = current;
        if (current.iframe) {
          load_iframe();
        } else if (current.iframe === "") {
          showToast(null, "错误：", "此链接为空！");
        }
      }
    );
    const load_iframe = () => {
      showToast(null, "提示：", "页面加载中...");
      const postData = {
        accessToken: localStorage.getItem("accessToken"),
        project_id: 72,
      };
      nextTick(() => {
        const iframe_dom = document.getElementById("jsf-iframe");
        console.log("iframe_dom", iframe_dom);
        if (iframe_dom) {
          iframe_dom.onload = () => {
            iframe_dom.contentWindow.postMessage(postData, "*");
            console.log("已向iframe发送数据", postData);
            // showToast(null, '成功：', '页面已加载！');
          };
        }
      });
    };
    return {
      skin,
      shallShadowBottom,
      currentMenu,
    };
  },
};
</script>

<style scoped></style>
